@charset "UTF-8";

/* =============================================================================
MIXIN: BORDER RADIUS
USEAGE: @include border-radius(15px);
============================================================================= */

@mixin border-radius($radius) {
	border-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER TOP LEFT RADIUS
USEAGE: @include border-top-left-radius(15px);
============================================================================= */

@mixin border-top-left-radius($radius) {
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER TOP RIGHT RADIUS
USEAGE: @include border-top-right-radius(15px);
============================================================================= */

@mixin border-top-right-radius($radius) {
	border-top-right-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER BOTTOM RIGHT RADIUS
USEAGE: @include border-bottom-right-radius(15px);
============================================================================= */

@mixin border-top-right-radius($radius) {
	border-bottom-right-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER BOTTOM LEFT RADIUS
USEAGE: @include border-bottom-left-radius(15px);
============================================================================= */

@mixin border-top-left-radius($radius) {
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER TOP RADIUS
USEAGE: @include border-top-radius(15px);
============================================================================= */

@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER RIGHT RADIUS
USEAGE: @include border-right-radius(15px);
============================================================================= */

@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER BOTTOM RADIUS
USEAGE: @include border-bottom-radius(15px);
============================================================================= */

@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER LEFT RADIUS
USEAGE: @include border-left-radius(15px);
============================================================================= */

@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

/* =============================================================================
MIXIN: BORDER ALL RADIUS
USEAGE: @include border-left-radius(15px, 0, 15px, 0);
============================================================================= */

@mixin border-all-radius(
	$radius-top-left,
	$radius-top-right,
	$radius-bottom-right,
	$radius-bottom-left
) {
	border-top-left-radius: $radius-top-left;
	border-top-right-radius: $radius-top-right;
	border-bottom-right-radius: $radius-bottom-right;
	border-bottom-left-radius: $radius-bottom-left;
	background-clip: padding-box;
}
