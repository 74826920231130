@charset "UTF-8";

/* =============================================================================
MIXIN: TRIANGLE
USEAGE: @include triangle(top, 20, 10, #333);
============================================================================= */

@mixin triangle($direction, $width, $height, $color: #333) {

  @if unitless($width) {
    $width: $width + 0px;
  }

  @if unitless($height) {
    $height: $height + 0px;
  }

  content: '';
  display: inline-block;
  position: absolute;
  width: 0 !important;
  height: 0 !important;

  border-style: solid;

  @if ($direction == top) {

    border-width: 0 $width/2 $height $width/2;
    border-color: transparent transparent $color transparent;

  } @else if ($direction == right) {

    border-width: $height/2 0 $height/2 $width;
    border-color: transparent transparent transparent $color;

  } @else if ($direction == bottom) {

    border-width: $height $width/2 0 $width/2;
    border-color: $color transparent transparent transparent;

  } @else if ($direction == left) {

    border-width: $height/2 $width $height/2 0;
    border-color: transparent $color transparent transparent;

  }
}
