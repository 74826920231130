// Hamburger
// ==================================================
.o-hamburger {
    background-color: transparent!important;
	display: inline-block;
	cursor: pointer;
	outline: none;

	transition-property: opacity, filter;
	transition-duration: $hamburger-hover-transition-duration;
	transition-timing-function: $hamburger-hover-transition-timing-function;

	// Normalize (<button>)
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
	top: 0;
	right: -15px;
	position: relative;
	padding: 0;
    line-height: 60px;
    width: 60px;

	@media (min-width: breakpoint-min(sm)) {
		top: 8px;
	}

	@media (min-width: breakpoint-min(md)) {
		top: 10px;
	}

	@media (min-width: breakpoint-min(lg)) {
		top: 0;
	}

	&:hover {
		@if $hamburger-hover-use-filter == true {
			//filter: $hamburger-hover-filter;
		} @else {
			//opacity: $hamburger-hover-opacity;
		}
	}
}

.o-hamburger-box {
	width: $hamburger-layer-width;
	height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
	display: inline-block;
	position: relative;
	top: 5px;
}

.o-hamburger-inner {
	display: block;
	top: 50%;
	margin-top: $hamburger-layer-height / -2;

	&,
	&::before,
	&::after {
		width: $hamburger-layer-width;
		height: $hamburger-layer-height;
		background-color: $hamburger-layer-color;
		border-radius: $hamburger-layer-border-radius;
		position: absolute;
		transition-property: transform;
		-webkit-transition-property: transform;
		transition-duration: 0.15s;
		-webkit-transition-duration: 0.15s;
		transition-timing-function: ease;
		-webkit-transition-timing-function: ease;
	}

	&::before,
	&::after {
		content: '';
		display: block;
	}

	&::before {
		top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}

	&::after {
		bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
}
