@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

/* =============================================================================
  BASE
============================================================================= */

@each $name, $value in $colors {
	.txt--#{$name} {
		color: $value !important;
	}

	.bg--#{$name} {
		background-color: $value !important;
	}

	.bdr--#{$name} {
		border-color: $value !important;
	}
}
