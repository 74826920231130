@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'dropdown';

$dropdown-primary-color: map-get($colors, primary);
$dropdown-secondary-color: map-get($colors, secondary);
$dropdown-background-color: map-get($colors, white);
$dropdown-font-color: choose-contrast-color($dropdown-background-color);
$dropdown-border-radius: 5px;
$dropdown-border-style: solid;
$dropdown-border-width: 1px;
$dropdown-padding: 1rem 1rem;
$dropdown-icon-padding: 10px;
$dropdown-padding-right: ($dropdown-icon-padding * 3);
$dropdown-transition-speed: 200ms;
$dropdown-transition-curve: ease-in-out;
$dropdown-min-width: 200px;
$dropdown-arrow-icon: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-128.png';

/* =============================================================================
BASE
============================================================================= */


%dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: $dropdown-font-color;
    outline: none;
    background-color: map-get($colors, white);
    background-color: $dropdown-background-color;
    padding: $dropdown-padding;
    padding-right: $dropdown-padding-right;
    min-width: $dropdown-min-width;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //Arrow icon is set as background-image of the select and positioned $dropdown-icon-padding pixels from the right
    background: $dropdown-background-color url($dropdown-arrow-icon) no-repeat;
    background-size: 20px;
    background-position:  right $dropdown-icon-padding center;
    @include border-radius($dropdown-border-radius);
}


.o-#{$object-name} {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    
    select {
        color: $dropdown-font-color;
        outline: none;
        background-color: map-get($colors, white);
        background-color: $dropdown-background-color;
        padding: $dropdown-padding;
        padding-right: $dropdown-padding-right;
        min-width: $dropdown-min-width;
        -webkit-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        //Arrow icon is set as background-image of the select and positioned $dropdown-icon-padding pixels from the right
        background: $dropdown-background-color url($dropdown-arrow-icon) no-repeat;
        background-size: 20px;
        background-position:  right $dropdown-icon-padding center;
        @include border-radius($dropdown-border-radius);
    }

    &--primary {
        border: $dropdown-border-width $dropdown-border-style $dropdown-primary-color;
    }

    &--secondary {
        border: $dropdown-border-width $dropdown-border-style $dropdown-secondary-color;
    }
}