/* START COOKIEBOT */

#CybotCookiebotDialog{
    border: 0px !important;
}

#CybotCookiebotDialogBody{
    padding-right: 10% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;
}

#CybotCookiebotDialogBodyButtonAccept{
    padding: 2% !important;
}
#CybotCookiebotDialogBodyButtonDecline{
    background: none !important;
    color: black !important;
    text-decoration: underline !important;
    border: 0px !important;
  	float: right !important;
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px){
    #CybotCookiebotDialog{top: 5% !important; left: 5% !important;}
}

/* END COOKIEBOT*/
