@charset "UTF-8";

// Reference: http://primercss.io/utilities/#truncation

/* =============================================================================
  CONFIG
============================================================================= */

$truncate-max-width:        125px;


/* =============================================================================
  BASE
============================================================================= */


.css-truncate.css-truncate-target,
.css-truncate .css-truncate-target {
    display: inline-block;
    max-width: $truncate-max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
}

// On hover it reveals the entire text
.css-truncate.expandable:hover .css-truncate-target {
    max-width: none;
}
