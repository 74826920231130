@charset "UTF-8";

/* =============================================================================
MIXIN: CAPTION
USEAGE: @include box-shadow();
============================================================================= */

@mixin box-shadow() {
	box-shadow: 0px 4px 6px -2px rgba(0,0,0,0.15);
}
