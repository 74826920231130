@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

$module-name: 'cookie-banner';

/* =============================================================================
  BASE
============================================================================= */

.m-#{$module-name} {
	position: fixed;
	width: 100vw;
	padding: $padding-small;
	background-color: map-get($colors, yellow);
	bottom: 0;
	z-index: map-get($z-index, popover);
	display: none;
	box-shadow: 0px -5px 22px -9px rgba(0, 0, 0, 0.75);

	.l-row {
		@media (min-width: breakpoint-min(sm)) {
			display: flex;
		}
	}

	&__button {
		margin-left: $margin-small;

		@media (min-width: breakpoint-min(sm)) {
			float: right;
		}
	}

	&__align {
		display: flex;
		align-items: center;
	}

	&__margin {
		margin: 10px 0;
		text-align: center;

		@media (min-width: breakpoint-min(sm)) {
			text-align: unset;
		}
	}

	&--remove {
		bottom: -100vh;
		transition: all 400ms ease;

		@media (min-width: breakpoint-min(sm)) {
			bottom: -130px;
		}
	}

	&--show {
		display: block;
	}
}
