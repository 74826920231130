@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'multilanguage';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	float: left;

	@media (min-width: breakpoint-min(lg)) {
		margin-left: 12px;
	}

	@media (min-width: breakpoint-min(xl)) {
		margin-left: 20px;
	}

	&__list {
		margin: 0;

		li {
			display: inline;

			a {
				padding: 7px 0 7px 0;
				line-height: 45px;
				color: map-get($colors, gray-blue-text);

				&:after {
					content: '/';
					display: inline-block;
				}

				&:hover {
					color: map-get($colors, blue-dark);
				}
			}

			&:last-child {
				a:after {
					content: '';
				}
			}

			&.current-lang a {
				color: map-get($colors, blue-dark);

				&:after {
					color: map-get($colors, gray-blue-text);
				}
			}
		}
	}
}

.m-#{$module-name}--mobile {
	top: 0;
	right: -10px;
	position: relative;

	@media (min-width: breakpoint-min(sm)) {
		top: 10px;
		right: -5px;
	}

	&__menu {
		.menu-item-has-children {
			height: 60px;
			width: 60px;
			line-height: 60px;
			text-align: center;
			position: relative;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-top: 5px solid map-get($colors, gray-blue-text);
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 5px;
				line-height: 60px;
				transform-origin: 50% 0%;
				transform: translateY(-50%);
				@include transition(transform, 0.3s, ease-in-out);
			}

			a {
				padding: 1rem 2rem 1rem 1rem;
				color: map-get($colors, gray-blue-text);

				&:hover {
					color: darken(map-get($colors, gray-blue-text), 10%);
				}
			}

			&.is--active {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		.sub-menu {
			display: none;
			padding: 0;
			background: map-get($colors, white);
			padding: 1rem 0;
			list-style: none;

			.menu-item {
				line-height: 1;
			}

			a {
				padding: 1rem;
				display: block;
			}
		}
	}
}
