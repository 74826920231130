@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'logo';
$logo-width: 197px;
$logo-height: 90px;
$logo-img-url: './assets/img/logo.svg';
$logo-img-url-de: './assets/img/logo-de.svg';
$logo-img-width--mobile: 170px;
$logo-img-height--mobile: 32px;
$logo-img-width--medium: 250px;
$logo-img-height--medium: 41px;
$logo-img-width: 300px;
$logo-img-height: 50px;

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	width: $logo-img-width--mobile;
	height: $logo-img-height--mobile;
	background: url($logo-img-url);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: inline-block;

	@media (min-width: breakpoint-min(sm)) {
		width: $logo-img-width--medium;
		height: $logo-img-height--medium;
	}

	@media (min-width: breakpoint-min(lg)) {
		width: $logo-img-width;
		height: $logo-img-height;
	}

	&--circle {
		width: 50px;
		height: 50px;
		display: inline-block;

		img {
			width: 100%;
			height: auto;
		}
	}

	&--de {
		background: url($logo-img-url-de);
		background-repeat: no-repeat;
		background-position-y: center;
	}

}
