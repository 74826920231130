@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'search';

/* =============================================================================
BASE
============================================================================= */
#m-#{$module-name} {
	padding-top: 4.5rem;
}

.m-#{$module-name} {
	padding-top: 19px;
	padding-bottom: 19px;
	background: map-get($colors, gray-lighter);

	@media (min-width: breakpoint-min(md)) {
		margin: 0 20px;
	}

	@media (min-width: breakpoint-min(xl)) {
		height: 80px;
	}

	form {
		margin: 0 auto;
	}

	&__label {
		line-height: 42px;
		display: inline-block;
		margin-right: $margin-small;

		@media (min-width: breakpoint-min(md)) {
			margin-right: $margin-medium;
		}
	}

	&__select {
		display: inline-block;
		margin-right: $margin-small !important;
		margin-bottom: $margin-small !important;


		select {
			max-width: none;
			margin: 0;
			border: 0 !important;
		}


		@media (min-width: breakpoint-min(sm)) {
			margin-bottom: 0;
		}

		@media (min-width: breakpoint-min(md)) {
			margin-right: $margin-medium;
		}
	}

	&__submit {
		width: 100%;

		@media (min-width: breakpoint-min(sm)) {
			margin-top: $margin-small;
		}

		@media (min-width: breakpoint-min(md)) {
			margin-top: 0;
		}
	}
}
