@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'blog';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {

}
