@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: "campaign";

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	.o-btn {
		margin-top: 15px;
		// margin-left: 5px;
		// margin-right: 5px;

		.m-contentbanner {
			margin-top: 0;
		}
	}
}

.page-template-page-campaign {
	.m-hero {
		h1.txt--white {
			color: map-get($colors, blue-dark) !important;
		}
	}

	#m-campaign__c2a {
		.m-contentbanner {
			.txt--white {
				color: map-get($colors, blue-dark) !important;
			}
		}
	}
}

.page-template-page-campaign-2 {
	.m-hero {
		h1.txt--white {
			//color: map-get($colors, blue-dark) !important;
			max-width: 450px;
		}
	}

	.m-campaign {
		&__buttons {
			margin-bottom: 45px;
			margin-top: 30px;
			overflow: hidden;

			@media (min-width: breakpoint-min(md)) {
				margin-top: 0;
			}

			.o-btn {
				background: map-get($colors, yellow) !important;
				color: map-get($colors, blue-dark) !important;
				margin-top: 0;
				width: 100%;
				margin-bottom: 10px;

				&:hover,
				&:focus {
					background: darken(
						map-get($colors, yellow),
						10%
					) !important;
				}
			}
		}

		&__team {
			margin-bottom: -15px;

			h2 {
				margin-top: 0;
			}

			&__members {
				margin-left: -10px;
				margin-right: -10px;

				@media (min-width: breakpoint-min(lg)) {
					margin-left: -15px;
					margin-right: -15px;
				}
			}
		}

		&__usps {
			margin-bottom: 15px;

			@media (min-width: breakpoint-min(md)) {
				margin-bottom: 20px;
			}

			@media (min-width: breakpoint-min(xl)) {
				margin-bottom: 30px;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					position: relative;
					padding-bottom: 10px;
					padding-left: 30px;

					i {
						position: absolute;
						left: 0;
						top: 5px;
						width: 20px;
						color: map-get($colors, yellow);
						margin-right: 10px;
					}
				}
			}
		}
	}

	#m-campaign__c2a {
		@media (min-width: breakpoint-min(md)) {
			display: flex;
		}

		&__content {
			background: map-get($colors, blue-dark);
			overflow: hidden;
			padding: 15px;

			@media (min-width: breakpoint-min(md)) {
				padding: 20px;
				position: relative;
				height: 100%;
			}

			@media (min-width: breakpoint-min(xl)) {
				padding: 30px;
			}

			.h1 {
				color: map-get($colors, white) !important;
				margin-top: 0;

				strong {
					color: map-get($colors, yellow) !important;
					font-family: Rozha One;
					font-weight: normal;
				}
			}

			.m-form {
				.activedemand-wrapper {
					padding: 0;
					font-family: Merriweather, Helvetica Neue, Helvetica, Arial,
						sans-serif;
				}

				.activedemand-label {
					color: map-get($colors, white) !important;
					margin-bottom: 0;
				}

				.checkbox {
					.opt_in.activedemand-input {
						position: relative;
						left: 0;
						top: 1px;
					}
				}

				.ad-form-field {
					padding: 0;
				}
				.cta-content-wrapper {
					padding: 0;
					margin-top: 30px;
				}
				.activedemand-button-container {
					text-align: left;
				}
			}

			.m-content {
				color: map-get($colors, white) !important;
			}

			.btn.activedemand-button {
				background: map-get($colors, yellow) !important;
				color: map-get($colors, blue-dark) !important;

				&:hover,
				&:focus {
					background: darken(
						map-get($colors, yellow),
						10%
					) !important;
				}
			}

			input[type="text"] {
				max-width: none !important;
			}
		}

		.m-contentbanner {
			margin-top: 30px;

			@media (min-width: breakpoint-min(md)) {
				margin-top: 0;
			}

			h2,
			p {
				text-shadow: 2px 2px 60px #000000;
			}
		}
	}

	.m-team__persons {
		.m-team__person {
			@media (min-width: breakpoint-min(md)) {
				width: 240px;
				height: 240px;
			}

			@media (min-width: breakpoint-min(lg)) {
				width: 315px;
				height: 315px;
			}

			@media (min-width: breakpoint-min(xl)) {
				width: 380px;
				height: 380px;
			}

			.o-person__img {
				@media (max-width: breakpoint-max(xs)) {
					width: 100%;
					height: 0;
					padding-top: 100%;
				}

				img {
					@media (max-width: breakpoint-max(xs)) {
						position: absolute;
						top: 0;
						left: 0;
					}
					@media (min-width: breakpoint-min(sm)) {
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
}
