@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'form';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	position: relative;
	margin-top: 30px;

	.form-group {

		label {
			display: block;
			margin-bottom: 5px;

			@media (min-width: breakpoint-min(sm)) {
				// display: inline-block;
				// width: 200px;
			}
		}
	}

	textarea {
		border: 2px solid map-get($colors, gray-blue);

		&:hover, &:focus {
			border-color: darken($inputfield-border-color--primary, 30%);
		}
	}

	.btn {
		padding: 1rem 3rem !important;
		margin-top:20px;
	}

	label.error {
		margin-left: 0 !important;
    	margin-bottom: 20px !important;
	}


	.column {
		padding: 0 !important;
	}

	.form-group {
		margin-bottom: 15px;
	}

	.form-control.file {
		margin-bottom: 15px;
	}

	input[type='text'] {
		width: 100%;
	}
}

/* Active Demand overrules */
.ad-form-field {
	padding: 0!important;
	margin: 0!important;

	input, textarea {
		border-radius: 0;
		border: 2px solid map-get($colors, gray-blue)!important;
		padding: .9rem 1rem!important;
		font-family: sans-serif!important;
		font-size: 16px!important;
		line-height: 1.15!important;

		&:hover, &:focus {
			border-color: map-get($colors, gray-blue-text)!important;
		}
	}
	select {
		border-radius: 0!important;
		border: 2px solid map-get($colors, gray-blue)!important;
		padding: .9rem 1rem!important;
		max-width: 300px!important;
		min-width: 100px!important;
		outline: none!important;
		margin: 0 0 1rem!important;
		background: none!important;
		height: 40px!important;
	}
}
.cta_button {
	button {
		background:map-get($colors, orange)!important;
		font-family: Merriweather Bold!important;
		font-size: 1.6rem!important;
		padding: 1rem 45px 1rem 1.5rem!important;
    	position: relative!important;

		&:hover {
			background: darken(map-get($colors, orange), 10%)!important;
		}
	}
}
.activedemand-button-container {
	text-align: left!important;
}
