@charset "UTF-8";

/* =============================================================================
GRID SYSTEM
============================================================================= */

$container-maxwidth: 1170px;

/* =============================================================================
SELECTOR: CONTAINER WIDTHS
DESCRIPTION: Set the container width, and override it for fixed navbars in media queries.
============================================================================= */

.l-container {
    max-width: $container-maxwidth;
    @include make-container();
}

/* =============================================================================
SELECTOR: FLUID CONTAINER
DESCRIPTION: Utilizes the mixin meant for fixed width containers, but with 100% width for fluid, full width layouts.
============================================================================= */

.l-container--fluid {
    @include make-container();
}

/* =============================================================================
SELECTOR: ROW
DESCRIPTION: Rows contain and clear the floats of your columns.
============================================================================= */

.l-row {
    @include make-row(grid);
    @include clearfix();
}
// Remove the negative margin from default .l-row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.l-no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .l-col,
    > [class*='l-col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}

/* =============================================================================
SELECTOR: COLUMNS
DESCRIPTION: Common styles for small and large grid columns
============================================================================= */

@include make-grid-columns(l, grid);


.flexbox {
    .l-row.l-row--eq-h-md-up {
		flex-wrap: wrap;
        @media (min-width: breakpoint-min(md)) {
			display: flex;

            .l-col, [class*='l-col-'] {


            }
        }

    }
}

.no-flexbox {
    .l-row.l-row--eq-h-sm-up {

    }
}

.ie10 {
	 .l-row.l-row--eq-h-md-up {

        @media (min-width: breakpoint-min(md)) {
			//display: flex;
			display: -ms-flexbox;

            .l-col, [class*='l-col-'] {

            }
        }

    }
}
