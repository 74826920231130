@charset "UTF-8";

/* =============================================================================
PRINT HELPER CLASSES
Utilities for toggling `display` in print
============================================================================= */

.d-print-block {
	display: none !important;

	@media print {
		display: block !important;
	}
}

.d-print-inline {
	display: none !important;

	@media print {
		display: inline !important;
	}
}

.d-print-inline-block {
	display: none !important;

	@media print {
		display: inline-block !important;
	}
}

.d-print-none {
	@media print {
		display: none !important;
	}
}
