@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

$print-text-color: #999999;

/* =============================================================================
  BASE
============================================================================= */

@media screen {
	.print {
		display: none;
	}
}

@media print {
	* {
		// scss-lint:disable ImportantRule
		background: transparent !important;
		box-shadow: none !important;
		color: map-get(
			$colors,
			black
		) !important; // Black prints faster: h5bp.com/s
		text-shadow: none !important;
		// scss-lint:enable ImportantRule
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: ' (" attr(href) ")';
	}

	abbr[title]:after {
		content: ' (" attr(title) ")';
	}

	// scss-lint:enable QualifyingElement

	// Don't show links for images, or javascript/internal links
	// scss-lint:disable QualifyingElement
	.ir a:after,
	a[href^='javascript:']:after,
	a[href^='#']:after {
		content: '';
	}
	// scss-lint:enable QualifyingElement
	pre,
	blockquote {
		border: 1px solid $print-text-color;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; // h5bp.com/t
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		// scss-lint:disable ImportantRule
		max-width: 100% !important;
		// scss-lint:enable ImportantRule
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.txt--white {
		color: map-get($colors, black) !important;
	}

	.no--print {
		display: none;
	}
}
