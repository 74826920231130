@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'block';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	width: 100%;
	height: 100%;
	display: block;

	@include transition(all, 200ms, ease-in-out);

	&--pagetitle {
		@media (min-width: breakpoint-min(md)) {
			min-height: 160px;
		}
		@media (min-width: breakpoint-min(lg)) {
			min-height: 200px;
		}
	}

	&__title {
		position: relative;
		min-height: 50px;
		border-left: 6px solid map-get($colors, blue-dark);
		margin-bottom: 5px;

		@media (min-width: breakpoint-min(md)) {
			margin-bottom: 0;
			height: 110px;
		}

		@media (min-width: breakpoint-min(md)) {
			border-left: 10px solid map-get($colors, blue-dark);
		}

		h3 {
			padding-bottom: 10px;

			@media (min-width: breakpoint-min(md)) {
				position: absolute;
				bottom: 0;
				left: 0;
				padding-bottom: 15px;
			}
		}
	}

	&__text {
		border-left: 10px solid transparent;

		a {
			color: map-get($colors, gray-blue-text);
		}

		.o-btn + .o-btn {
			margin-top: $margin-small;
		}

		@media (max-width: breakpoint-max(md)) {
			padding-top: 0;
		}

		p {
			color: map-get($colors, gray-blue-text);
		}
	}

	&--bdr-yellow {
		.o-block__title {
			border-left: 6px solid map-get($colors, yellow);

			@media (min-width: breakpoint-min(md)) {
				border-left: 10px solid map-get($colors, yellow);
			}
		}
	}

	&:hover {
		transform: scale(1.025);
	}

	&--no-hover {
		transform: scale(1) !important;
	}
}
