@charset "UTF-8";

/* =============================================================================
MIXIN: CALCULATE FONTSIZE (PX & REM) BASED ON BASESIZE
USEAGE: @include placeholder(#ccc);
============================================================================= */

@mixin placeholder($color){

  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }
  
  &:-ms-input-placeholder {
    color: $color;
  }

}
