@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: "jobs-apply";

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	position: relative;

	@media (min-width: breakpoint-min(lg)) {
		margin-left: -30px;
		margin-right: -30px;
	}

	&__title {
		@media (min-width: breakpoint-min(md)) {
			padding-right: 33%;

			h2 {
				padding-right: 30px;
			}
		}

		@media (min-width: breakpoint-min(lg)) {
			min-height: 82px;

			h2 {
				line-height: 40px;
			}
		}

		@media (min-width: breakpoint-min(xl)) {
			min-height: 102px;
		}
	}

	&__cta {
		@media (min-width: breakpoint-min(md)) {
			width: 33%;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			height: 100%;
			padding: 0;
			display: flex;
		}

		@media (min-width: breakpoint-min(lg)) {
			min-height: 82px;
		}

		@media (min-width: breakpoint-min(xl)) {
			min-height: 102px;
		}

		.o-btn {
			@media (min-width: breakpoint-min(md)) {
				margin: 0 20px;
				width: calc(100% - 40px);
				display: flex;
				align-self: center;
			}
		}
	}
}

#m-#{$object-name}.is--fixed {
	z-index: 1050;
	position: fixed;
	top: 60px;
	width: 100%;

	@media (min-width: breakpoint-min(sm)) {
		top: 75px;
	}

	@media (min-width: breakpoint-min(lg)) {
		top: 150px;
	}

	@media (min-width: breakpoint-min(xl)) {
		top: 150px;
		width: 1170px;
		left: 50%;
		transform: translateX(-50%);
	}
}
