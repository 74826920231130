@charset "UTF-8";

/* =============================================================================
MIXIN: CAPTION
USEAGE: @include caption(colorName);
============================================================================= */

@mixin caption($txt-color) {
	text-align: left;
	color: map-get($colors, $txt-color);
	padding-top: 8px;
	padding-bottom: 8px;
}
