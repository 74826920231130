@charset "UTF-8";

/* =============================================================================
MIXIN: BLEND MULTIPLY
USEAGE: @include blend-multiply($color);
============================================================================= */

@mixin blend-multiply($color) {
	background-color: $color;
  	background-blend-mode: multiply;
}

/* =============================================================================
MIXIN: MIX BLEND MULTIPLY
USEAGE: @include mix-blend-multiply();
============================================================================= */

@mixin mix-blend-multiply() {
  	mix-blend-mode: multiply;
}
