@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'card';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	position: relative;
	height: 200px;
	overflow: hidden;
	display: block;

	@media (min-width: breakpoint-min(lg)) {
		height: 250px;
	}

	&__fade {
		position: absolute;
		pointer-events: none!important;
		height: 50%;
		bottom: 0;
		left: -1px;
		right: -1px;
		z-index: 2;
		background: -moz-linear-gradient(
		  top,
		  rgba(0, 0, 0, 0) 0%,
		  rgba(0, 0, 0, 0.65) 100%
		);
		background: -webkit-linear-gradient(
		  top,
		  rgba(0, 0, 0, 0) 0%,
		  rgba(0, 0, 0, 0.65) 100%
		);
		background: linear-gradient(
		  to bottom,
		  rgba(0, 0, 0, 0) 0%,
		  rgba(0, 0, 0, 0.65) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
	}

	&__content {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 3;

		h4:last-child {
			margin-bottom: 0;
		}
	}

	&__bg {
		height: 200px;
		@include transition(transform, 0.5s, ease-in-out);

		@media (min-width: breakpoint-min(lg)) {
			height: 250px;
		}
	}

	&:hover {
		.o-#{$object-name}__bg {
			transform: scale(1.2);
			@include transition(transform, 0.5s, ease-in-out);
			//background-blend-mode: normal;
			background-color: map-get($colors, white);
		}
	}

	&--news {
		height: auto;
		@include transition(all, 200ms, ease-in-out);

		.o-card__content {
			position: relative;

			h3 {
				margin-top:0;
				margin-bottom: 10px;
			}

			p {
				color: map-get($colors, gray-blue-text);

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&:hover {
			transform: scale(1.025);
		}
	}
}
