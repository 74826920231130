@charset "UTF-8";

/* =============================================================================
MIXIN: RESET
USEAGE: @include reset();
============================================================================= */

@mixin reset {
  margin: 0;
  padding: 0;
}
