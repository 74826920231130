@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'question';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {

	@media (max-width: breakpoint-max(sm)) {
		text-align: center !important;
		display: block;
	}

	p {
		display: inline-block;
		margin: 0;
		padding-right: 30px;

		@media (max-width: breakpoint-max(sm)) {
			display: block;
			padding: 0;
			margin-bottom: 20px;
		}
	}

	.o-btn {

		@media (max-width: breakpoint-max(xs)) {
			width: 100%;
		}

		@media (max-width: breakpoint-max(sm)) {
			width: auto;
		}
	}
}
