@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'read-more';
$read-more--color: map-get($colors, primary);

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	color: $read-more--color;

	&:hover {
		color: darken($read-more--color, 10%);
	}

	&__icon {
		&-l {
			position: relative;
			top: 1px;
			padding-right: 5px;
		}

		&-r {
			position: relative;
			top: 1px;
			padding-left: 5px;
		}
	}
}
