@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'header';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	position: relative;
	z-index: 1;
	height: 60px;
	background: map-get($colors, white);
	@include box-shadow();

	@media (min-width: breakpoint-min(sm)) {
		height: 75px;
	}

	@media (min-width: breakpoint-min(lg)) {
		height: 100px;
	}

	.o-logo {
		position: absolute;
		top: 15px;

		@media (min-width: breakpoint-min(sm)) {
			top: 18px;
		}

		@media (min-width: breakpoint-min(lg)) {
			top: 25px;
		}
	}

	&__actions {
		display: none;

		@media (min-width: breakpoint-min(lg)) {
			display: inline-block;
			float: right;
			padding-top: 25px;
		}

		a {
			font-family: $merriweather--bold;
		}
	}
}
