@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: "logo-carousel";


$hero-height: 350px;
$hero-height-xs: 300px;
$hero-height-sm: 350px;
$hero-height-md: 400px;
$hero-height-lg: 450px;
$hero-height-xl: 500px;

$button-background-color: map-get($colors, blue-dark);
$button-icon-color: map-get($colors, white);
$button-border-radius: 50%;

$dot-background-color: map-get($colors, white);
$dot-active-background-color: map-get($colors, orange);

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	position: relative;
	padding-left: 45px;
	padding-right: 45px;

	h5 {
		font-family: "Merriweather";
	}


	&__container {
		position: relative;
		visibility: hidden;

		&.slick-initialized {
			visibility: visible;
		}
	}

	.slick-slide {
		float: left;
		padding: 10px;
		display: none;
	}

	.slick-list {
		overflow: hidden;
		height: 120px;
	}

	&__item {
		height: 110px;
		background: white;
		outline: 0;

		text-align: center;

		img {
			width: auto;
			height: 110px;
		}
	}

	.slick-initialized .slick-slide {
		display: block;
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -35px;
		height: 30px;
		width: 30px;
		// margin-top: -15px;
		text-align: center;
		background: $button-background-color;
		border-radius: $button-border-radius;
		cursor: pointer;
		z-index: 1;
		transition: all 0.3s;
		// @include box-shadow-light();

		// @media (min-width: breakpoint-min(sm)) {
		// 	left: -35px;
		// }

		@media (min-width: breakpoint-min(md)) {
			// left: 15px;
			// height: 40px;
			// width: 40px;
			// margin-top: -20px;
		}

		@media (min-width: 1300px) {
			// left: -60px;
		}

		i , svg {
			position: relative;
			top: 4px;
			left: -2px;
			font-size: 18px;
			line-height: 18px;
			color: $button-icon-color;

			@media (min-width: breakpoint-min(sm)) {
				// top: 1px;
				// font-size: 24px;
			}

			// @media (min-width: breakpoint-min(md)) {
			// 	top: 8px;
			// 	font-size: 24px;
			// 	line-height: 24px;
			// }
		}

		&.slick-disabled {
			cursor: default;
			opacity: 0.25;
		}

		&:hover, &:focus, &:active {
			background: darken($button-background-color , 10%);
		}
	}

	&__next {
		right: -35px;
		left: auto;

		i , svg {
			left: 1px;
		}
	}

	&__dots {
		position: absolute;
		bottom: 15px;
		left: 15px;
		display: none;

		@media (min-width: breakpoint-min(md)) {
			bottom: 20px;
			display: block;
		}

		@media (min-width: breakpoint-min(lg)) {
			bottom: 30px;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin-left: -7px;
			margin-bottom: 0;

			li {
				display: inline-block;

				button {
					position: relative;
					margin: 5px;
					padding: 0;
					height: 15px;
					width: 15px;
					border-radius: 10px;
					text-indent: -9999px;
					overflow: hidden;
					background: $dot-background-color !important;
					transition: all 0.3s;

					&:hover, &:active, &:focus {
						background: darken($dot-background-color , 10%) !important;
					}
				}

				&.slick-active {

					button {
						background: $dot-active-background-color !important;
					}
				}
			}
		}
	}
}
