@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'team';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {

	&__group {

	}

	&__persons {
		overflow: hidden;
		margin-left: -8px;
		margin-right: -8px;

		@media (min-width: breakpoint-min(md)) {
			margin-left: -10px;
			margin-right: -10px;
		}

		@media (min-width: breakpoint-min(lg)) {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	&__person {
		width: 100%;
		padding: 8px;

		@media (min-width: breakpoint-min(sm)) {
			margin-bottom: 15px;
			height: 200px;
		}
		@media (min-width: breakpoint-min(md)) {
			margin-bottom: 0;
			float: left;
			width: 250px;
			height: 250px;
			padding: 10px;
		}

		@media (min-width: breakpoint-min(lg)) {
			// width: 200px;
			// height: 200px;
			padding: 15px;
		}

		@media (min-width: breakpoint-min(xl)) {
			width: 250px;
			height: 250px;
		}
	}
}
