/* =============================================================================
  FONTS
============================================================================= */


$font-weight-ultralight : 100;
$font-weight-light      : 200;
$font-weight-book       : 300;
$font-weight-regular    : 400;
$font-weight-medium     : 500;
$font-weight-bold       : 600;
$font-weight-extrabold  : 700;
$font-weight-black      : 800;
