/* =============================================================================
	GRID BREAKPOINTS
  	Define the minimum dimensions at which your layout will change,
  	adapting to different screen sizes, for use in media queries.
============================================================================= */

$grid-breakpoints: (
		d: 0,
		xs: 480px,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px
	)
	!default;
