@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

/* =============================================================================
  BASE
============================================================================= */

/* =============================================================================
  FONTS
============================================================================= */

@import 'fonts/rozha-one';
@import 'fonts/merriweather';

/* =============================================================================
  ICONS
============================================================================= */

// @import 'fonts/icons';
