/* =============================================================================
COLORS
============================================================================= */

$colors: (
	white: #ffffff,
	black: #000000,
	gray1: #111111,
	gray2: #222222,
	gray3: #333333,
	gray4: #444444,
	gray5: #555555,
	gray6: #666666,
	gray7: #777777,
	gray8: #888888,
	gray9: #999999,
	grayC: #cccccc,
	grayD: #dddddd,
	grayE: #eeeeee,
	gray-lighter: #f3f3f3,
	gray-light: #e5e5e8,
	gray-objects: #9faab3,
	gray: #e9e9eb,
	gray-dark: #bec5cb,
	gray-blue: #b2b9bf,
	gray-blue-text: #566979,
	gray-blue-dark: #566979,
	blue: #16aae2,
	blue-dark: #00192a,
	yellow: #ec9e2b,
	orange: #fc4600,
	primary: #102336,
	secondary: #fc4600,
	tertiary: #00b6e5
);

$colors-validation: (
	success-green: #4cae4c,
	error-red: #d9534f,
	warning-orange: #f0ad4e,
	info-blue: #007bff
);

/* =============================================================================
SOCIAL MEDIA COLORS
============================================================================= */

$color-social: (
	twitter: #00aced,
	facebook: #3b5998,
	googleplus: #dd4b39,
	pinterest: #cb2027,
	linkedin: #007bb6,
	youtube: #bb0000,
	vimeo: #aad450,
	instagram: #517fa4,
	flickr: #ff0084,
	dribbble: #ea4c89,
	whatsapp: #4dc247
);
