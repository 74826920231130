@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'navigation';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	display: none;
	position: fixed;
	top: 60px;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 100;
	background: map-get($colors, white);
	overflow: auto;

	@media (min-width: breakpoint-min(md)) {
		overflow: visible;
	}

	@media (min-width: breakpoint-min(lg)) {
		display: block !important;
		position: relative;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
		z-index: auto;
	}

	&__container {
		@media (min-width: breakpoint-min(lg)) {
			.menu-limbourg-partners-main-container {
				float: left;
			}
		}
	}

	&__nav {
		text-align: center;

		@media (min-width: breakpoint-min(lg)) {
			background: map-get($colors, gray-lighter);
			height: 50px;
			text-align: left;
			@include box-shadow();
		}
	}

	&__list {
		@media (min-width: breakpoint-min(lg)) {
			float: left;
		}
	}

	.sub-menu {
		padding: 0;
		padding-left: 15px;

		@media (min-width: breakpoint-min(lg)) {
			display: none;
			left: 15px;
			right: 15px;
			top: 50px;
			position: absolute;
			height: 0;
			padding-left: 0;
			transition-property: height, left, padding-left;
			transition-duration: 10s;
			transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

			&__border {
				width: 5px;
				position: absolute;
				margin-left: -38px;
				top: -38px;
				left: -2px;
				height: 100%;
				border-left: 4px solid map-get($colors, yellow);
				transition-property: height, left;
				transition-duration: 1s;
				transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
			}

			li {
				list-style-type: none;

				a {
					display: li;
					border-bottom: 0 !important;
				}
			}
		}

		@media (min-width: breakpoint-min(xl)) {
			&__border {
				margin-left: -38px;
			}
		}

		@media (max-width: breakpoint-max(md)) {
			padding-left: 30px !important;
		}
	}

	ul.menu {
		list-style: none;
		padding: 0;
		text-align: left;
		margin: 0;
		margin-top: 15px;
		position: static;
		color: map-get($colors, gray-blue-text);
		border-bottom: 1px solid map-get($colors, gray-light);

		@media (min-width: breakpoint-min(lg)) {
			margin-top: 0;
			display: inline-block;
			border: 0;
		}
	}

	ul.menu li {
		display: block;

		@media (min-width: breakpoint-min(lg)) {
			display: inline-block;
			padding: 0;
		}

		a {
			display: block;
			cursor: pointer;
			color: map-get($colors, gray-blue-text);
			padding: 10px 15px 10px 15px;
			border-top: 1px solid map-get($colors, gray-light);

			@media (min-width: breakpoint-min(lg)) {
				border: 0;
				line-height: 45px;
				display: inline-block;
				padding: 0px 0px 0px 12px;
				border-bottom: 5px solid map-get($colors, gray-lighter);
			}

			@media (min-width: breakpoint-min(xl)) {
				display: inline-block;
				padding: 0px 0px 0px 20px;
			}

			&:hover {
				color: map-get($colors, blue-dark);
			}
		}

		&.is--active > a {
			background: map-get($colors, gray-blue-dark);
			color: map-get($colors, white);

			@media (min-width: breakpoint-min(lg)) {
				background: none;
				// 	border-bottom: 5px solid map-get($colors, yellow);
				color: map-get($colors, gray-blue-dark);
			}
		}

		span {
			display: none;

			@media (min-width: breakpoint-min(lg)) {
				display: inline-block;
				position: relative;
				top: 6px;
				padding-left: 12px;
				@include rem(24);
				height: 22px;
				border-right: 2px solid map-get($colors, gray-blue-text);
			}

			@media (min-width: breakpoint-min(xlg)) {
				padding-left: 20px;
			}
		}

		&:hover {
			@media (min-width: breakpoint-min(lg)) {
				.sub-menu {
					padding: $padding-medium 0;
					margin: 0;
					background: map-get($colors, primary);
					z-index: map-get($z-index, navbar);
					list-style: disc;
					display: block;
					height: auto;
					transition-property: height, left, padding-left;
					transition-duration: 1s;
					transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

					li {
						display: list-item;
						text-align: left;
						padding-left: $padding-small;
						color: map-get($colors, white);
						position: relative;
						left: -24px;

						a {
							display: block;
							width: 100%;
							color: map-get($colors, white);

							&:hover {
								color: map-get($colors, yellow);
							}
						}
						&.is--active,
						&.is--active a {
							color: map-get($colors, yellow);
						}
					}
				}
			}
		}
	}

	&__lang {
		display: inline-block !important;

		@media (min-width: breakpoint-min(lg)) {
			padding-right: 0px !important;
			padding-left: 0px !important;
		}

		span {
			display: inline-block;
			padding-left: 5px;

			@media (min-width: breakpoint-min(lg)) {
				padding-left: 10px;
			}
		}
	}

	&__mobile {
		display: block;
		margin-top: $margin-large;

		@media (min-width: breakpoint-min(lg)) {
			display: none;
		}

		a {
			margin-bottom: $margin-medium;
			margin-top: 0;

			&:first-child {
				margin-right: 5px;
			}

			&:last-child {
				margin-left: 5px;
			}
		}
	}
}
