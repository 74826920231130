@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'pagination';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	color: map-get($colors, gray-blue-text);

	&__label {
		display: inline-block;
	}

	&__list {
		display: inline-block;
	}

	ul {
		display: inline-block;
		margin: 0;
		padding: 0;
		margin-left: 15px;
	}


	li {
		padding-left: 5px;
		display: inline-block;

		&.is--active {
			font-family: $font-family--primary--bold;
		}

		span, a {

			span {
				padding-left: 5px;
				display: inline-block;
			}
		}

		span.page-numbers.current {
			color: map-get($colors, black);
		}
	}

	a {
		// padding: 0 5px;
		color: map-get($colors, gray-blue-text);

		span {
			padding: 0 5px;
		}
	}
}
