@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'btn';
$button-font-family: $font-family--primary;
$button-font-weight: normal;
$button-font-color--default: map-get($colors, white);
$button-background--primary: map-get($colors, orange);
$button-font-color--primary: map-get($colors, white);
$button-background--secondary: map-get($colors, blue);
$button-font-color--secondary: map-get($colors, white);
$button-background--tertiary: map-get($colors, gray-light);
$button-font-color--tertiary: map-get($colors, black);
$button-radius: 0;
$border-radius-rounded: 0;
$button-padding-xs: 1rem 1.5rem;
$button-padding: 1rem 3rem;
$button-padding-xl: 1.5rem 4.5rem;
$button-border-width: 1px;
$button-border-style: solid;
$button-border-color: transparent;

/* =============================================================================
BASE
============================================================================= */

%button-base {
	color: $button-font-color--default;
	display: inline-block;
	margin-bottom: 0;
	font-weight: $button-font-weight;
	font-family: $button-font-family;
	line-height: 20px;
	text-align: left;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background-image: none;
	border: $button-border-width $button-border-style $button-border-color;
	outline: none;
	@include border-radius($button-radius);
	@include transition(all, 200ms, ease-in-out);
}

%button-primary {
	color: $button-font-color--secondary;
	background: $button-background--primary;

	&:hover {
		background: darken($button-background--primary, 10%);
	}
}


%button-secundary {
	color: $button-font-color--secondary;
	background: $button-background--secondary;

	&:hover {
		background: darken($button-background--secondary, 10%);
	}
}

.o-#{$object-name} {
	padding: $button-padding;
	@extend %button-base;
	@include rem(16);

	&--outline {
		background-color: map-get($colors, white);
		border: 2px $button-border-style $button-background--primary;
		color: map-get($colors, black);

		&:hover {
			border-color: darken($button-background--primary, 10%);
			background-color: map-get($colors, white);
		}
	}
}

.o-#{$object-name}--w100 {
	width: 100%;
}

.o-#{$object-name}--xs {
	padding: $button-padding-xs;
	@extend %button-base;
	@include rem(12);
}

.o-#{$object-name}--xl {
	padding: $button-padding-xl;
	@extend %button-base;
	@include rem(18);
}

.o-#{$object-name}--primary {
	@extend %button-primary;
}

.o-#{$object-name}--secondary {
	@extend %button-secundary;
}

.o-#{$object-name}--tertiary {
	color: $button-font-color--tertiary;
	background: $button-background--tertiary;

	&:hover {
		background: darken($button-background--tertiary, 10%);
	}
}

.o-#{$object-name}--white {
	color: map-get($colors, blue-dark);
	background: map-get($colors, white);

	i {
		border-color: transparent transparent transparent
			map-get($colors, blue-dark) !important;
	}

	&:hover {
		background: darken(map-get($colors, white), 10%);
	}
}

.o-#{$object-name}--rounded {
	@include border-radius($border-radius-rounded);
}

.o-#{$object-name}__icon {
	&-l {
		margin-right: $margin-xsmall;
	}

	&-r {
		margin-left: $margin-xsmall;
	}

	&--xl {
		&-l {
			margin-right: $margin-small;
		}

		&-r {
			margin-left: $margin-small;
		}
	}
}

.o-#{$object-name}--arrow {
	padding: $padding-small 45px $padding-small $padding-medium;
	position: relative;

	i {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 0 5px 10px;
		border-color: transparent transparent transparent #ffffff;
		display: inline-block;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}

	&-down {

		i {
			border-width: 10px 5px 0 5px;
			border-color: #ffffff transparent transparent transparent;
		}
	}
}

button {
	border: none;
	background-color: $button-background--primary;
	padding: $button-padding;
	@include rem(18);
	@extend %button-base;

	&:hover {
		background-color: darken($button-background--primary, 10%);
	}
}
