@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: "jobs";

/* =============================================================================
BASE
============================================================================= */

#m-#{$module-name} {
	padding-top: 4.5rem;
}

.m-#{$module-name} {
	h5 {
		font-family: $merriweather;
	}

	&__container {
		overflow: hidden;
		width: 250px;
		margin: 0 auto;
		position: relative;

		@media (min-width: 560px) {
			width: 500px;
		}

		@media (min-width: 870px) {
			width: 750px;
		}

		@media (min-width: 1100px) {
			width: 1000px;
			margin-left: auto;
		}
	}

	&__controls {
		margin-top: $margin-large;
		text-align: right;

		@media (min-width: 1100px) {
			display: none;
		}

		.o-btn {
			position: relative;
			border-radius: 50%;
			padding: 1rem;
			width: 40px;
			height: 40px;
			text-align: center;
			font-family: $font-family--primary--bold;
			@include rem(21);

			i {
				position: relative;
				width: 0;
				height: 0;
				border-style: solid;
				display: inline-block;
				top: -3px;
			}

			&.m-jobs__prev {
				i {
					left: -2px;
					border-width: 5px 10px 5px 0;
					border-color: transparent #ffffff transparent transparent;
				}
			}

			&.m-jobs__next {
				i {
					left: 2px;
					border-width: 5px 0 5px 10px;
					border-color: transparent transparent transparent #ffffff;
				}
			}
		}
	}

	&__list {
		position: absolute;
		display: flex;

		@media (min-width: 1100px) {
			margin-left: 0 !important;
		}
	}

	&__item {
		float: left;
		width: 250px;
		position: relative;
	}

	&__tile {
		align-items: center;
		padding: $padding-small;
		margin: $margin-small;
		display: flex;
		flex-direction: column;
		height: 100%;

		@media (min-width: breakpoint-min(md)) {
			padding: $padding-medium;
			margin: $margin-medium;
		}

		h4 {
			width: 100%;
		}

		.o-btn {
			margin-top: auto;
			margin-bottom: 15px;
		}
	}

	&__logo {
		margin-bottom: $margin-small;
		max-width: 100%;
		height: 130px;

		img {
			width: 100%;
			height: auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__title {
		line-height: 1;
	}

	&__desc {
		margin-bottom: $margin-medium;
	}

	&__more-link {
		// @include rem(21);

		&:hover {
			color: map-get($colors, blue-dark) !important;
		}
	}
}

.ie11 {
	.m-#{$module-name} {
		&__logo {
			flex: 0 0 auto;
		}
	}
}
