@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'footer-nav';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	height: 100%;
	position: relative;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
		color: map-get($colors, gray-blue-text);

		@media (min-width: breakpoint-min(lg)) {
			display: block;
			margin: 0 auto;
		}
	}

	.menu-item {
		display: block;
		padding: 15px 0;
		position: relative;

		@media (min-width: breakpoint-min(lg)) {
			display: inline-block;
			padding: 0;
		}

		a {
			display: block;
			cursor: pointer;
			color: map-get($colors, gray-blue-text);

			@media (min-width: breakpoint-min(lg)) {
				display: inline-block;
				padding: 7px 12px 7px 0;
			}

			@media (min-width: breakpoint-min(xl)) {
				display: inline-block;
				padding: 7px 20px 7px 0;
			}
		}

		span {
			display: none;

			@media (min-width: breakpoint-min(lg)) {
				display: inline-block;
				position: relative;
				top: 2px;
				@include rem(24);
				padding-left: 12px;
			}

			@media (min-width: breakpoint-min(lg)) {
				padding-left: 20px;
			}
		}
	}
}
