@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$merriweather: 'Merriweather';
$merriweather--bold: 'Merriweather Bold';
$merriweather--italic: 'Merriweather Italic';
$merriweather--bold-italic: 'Merriweather Bold Italic';
$merriweather--light: 'Merriweather Light';
$merriweather--light-italic: 'Merriweather Light Italic';
$merriweather--black: 'Merriweather Black';
$merriweather--black-italic: 'Merriweather Black Italic';

/* =============================================================================
BASE
============================================================================= */


@font-face {
    font-family: $merriweather--bold-italic;
    src: url('./assets/fonts/Merriweather/BoldItalic/Merriweather-BoldItalic.eot');
    src: url('./assets/fonts/Merriweather/BoldItalic/Merriweather-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/BoldItalic/Merriweather-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/BoldItalic/Merriweather-BoldItalic.woff') format('woff'),
        url('./assets/fonts/Merriweather/BoldItalic/Merriweather-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: $merriweather--light;
    src: url('./assets/fonts/Merriweather/Light/Merriweather-Light.eot');
    src: url('./assets/fonts/Merriweather/Light/Merriweather-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/Light/Merriweather-Light.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/Light/Merriweather-Light.woff') format('woff'),
        url('./assets/fonts/Merriweather/Light/Merriweather-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: $merriweather--black;
    src: url('./assets/fonts/Merriweather/Black/Merriweather-Black.eot');
    src: url('./assets/fonts/Merriweather/Black/Merriweather-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/Black/Merriweather-Black.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/Black/Merriweather-Black.woff') format('woff'),
        url('./assets/fonts/Merriweather/Black/Merriweather-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: $merriweather--black-italic;
    src: url('./assets/fonts/Merriweather/BlackItalic/Merriweather-BlackItalic.eot');
    src: url('./assets/fonts/Merriweather/BlackItalic/Merriweather-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/BlackItalic/Merriweather-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/BlackItalic/Merriweather-BlackItalic.woff') format('woff'),
        url('./assets/fonts/Merriweather/BlackItalic/Merriweather-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: $merriweather;
    src: url('./assets/fonts/Merriweather/Regular/Merriweather-Regular.eot');
    src: url('./assets/fonts/Merriweather/Regular/Merriweather-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/Regular/Merriweather-Regular.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/Regular/Merriweather-Regular.woff') format('woff'),
        url('./assets/fonts/Merriweather/Regular/Merriweather-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $merriweather--light-italic;
    src: url('./assets/fonts/Merriweather/LightItalic/Merriweather-LightItalic.eot');
    src: url('./assets/fonts/Merriweather/LightItalic/Merriweather-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/LightItalic/Merriweather-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/LightItalic/Merriweather-LightItalic.woff') format('woff'),
        url('./assets/fonts/Merriweather/LightItalic/Merriweather-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: $merriweather--italic;
    src: url('./assets/fonts/Merriweather/Italic/Merriweather-Italic.eot');
    src: url('./assets/fonts/Merriweather/Italic/Merriweather-Italic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/Italic/Merriweather-Italic.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/Italic/Merriweather-Italic.woff') format('woff'),
        url('./assets/fonts/Merriweather/Italic/Merriweather-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: $merriweather--bold;
    src: url('./assets/fonts/Merriweather/Bold/Merriweather-Bold.eot');
    src: url('./assets/fonts/Merriweather/Bold/Merriweather-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Merriweather/Bold/Merriweather-Bold.woff2') format('woff2'),
        url('./assets/fonts/Merriweather/Bold/Merriweather-Bold.woff') format('woff'),
        url('./assets/fonts/Merriweather/Bold/Merriweather-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

