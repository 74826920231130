@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$rozha-one: 'Rozha One';

/* =============================================================================
BASE
============================================================================= */

@font-face {
    font-family: $rozha-one;
    src: url('./assets/fonts/RozhaOne/RozhaOne-Regular.eot');
    src: url('./assets/fonts/RozhaOne/RozhaOne-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/RozhaOne/RozhaOne-Regular.woff2') format('woff2'),
        url('./assets/fonts/RozhaOne/RozhaOne-Regular.woff') format('woff'),
        url('./assets/fonts/RozhaOne/RozhaOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

