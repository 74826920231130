@if index($hamburger-types, boring) {
	/*
   * Boring
   */
	.o-hamburger--boring {
		.o-hamburger-inner {
			&,
			&::before,
			&::after {
				transition-property: none;
				-webkit-transition-property: none;
			}
		}

		&.is--active {
			.o-hamburger-inner {
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);

				&::before {
					top: 0;
					opacity: 0;
				}

				&::after {
					bottom: 0;
					transform: rotate(-90deg);
					-webkit-transform: rotate(-90deg);
				}
			}
		}
	}
}
