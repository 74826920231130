@charset "UTF-8";

/* =============================================================================
	CONFIG
============================================================================= */

$module-name: 'contentbanner';

/* =============================================================================
	BASE
============================================================================= */

.m-#{$module-name} {
	position: relative;

	.o-block__title {
		//position: absolute;
		overflow: hidden;
		height: auto;
		min-height: 110px;
	}

	&__container {
		bottom: 0;
		left: 0;
		right: 0;
		padding-left: 21px;

		@media (min-width: breakpoint-min(md)) {
			padding-left: 30px;
		}

		@media (min-width: breakpoint-min(lg)) {
			padding-left: 40px;
		}

		.o-btn {
			white-space: normal;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--img {
		min-height: 350px;
		max-height: 350px;
		// @include blend-multiply(map-get($colors, yellow) !important);

		.m-#{$module-name}__container {
			position: absolute;
		}
	}

	&.bg--gray-lighter {
		color: map-get($colors, blue-dark);

		.o-block__title {
			border-color: map-get($colors, yellow);
		}

	}
}
