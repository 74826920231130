@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'blocks';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {

}

.ie9, .safari5, .safari6_0 {
    .o-block {
        height: 200px;

        @media (min-width: breakpoint(sm)) {
            height: 250px;
        }

        @media (min-width: breakpoint-min(md)) {
            height: 300px;
        }

    }
}
