@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'jobs-specs';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	position: relative;

	.o-block__title {
		position: absolute;
		min-height: 80px;
	}

	&__title {
		margin-top: $margin-medium;
		margin-bottom: $margin-medium;
	}

	&__row {
		margin-bottom: $margin-large;
	}

	&__label {
		font-family: $font-family--primary--bold;
	}
}
