@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'hero';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	position:relative;
	height: 300px;
	display: flex;
	align-items: center;
	background-position: center;
	background-position-x: right;
	// @include blend-multiply(map-get($colors, yellow));
	background-position: center center;

	@media (min-width: breakpoint-min(sm)) {
		height: 320px;
	}

	@media (min-width: breakpoint-min(md)) {
		height: 360px;
	}

	@media (min-width: breakpoint-min(lg)) {
		height: 400px;
	}

	&__overlay {
		position:absolute;
		display:none;
		z-index:1;
		top:0;
		width:100%;
		height:100%;
		// background-color:map-get($colors, yellow);

		/*
		//IE 9 and down
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";

		// IE 10 and 11
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			display:block;
			opacity: 0.3;
		}
		*/
	}

	&__content {
		position:relative;
		z-index:2;
		.h3 {
			margin-bottom: 0;
			margin-top: 15px;
			font-family: $font-family--primary--bold, 'Helvetica Neue',
				Helvetica, Arial, sans-serif;
		}

		.h4 {
			margin-bottom: 0;
			margin-top: 5px;
			font-family: $font-family--primary, 'Helvetica Neue', Helvetica,
				Arial, sans-serif;
		}
	}

	&--small {
		height: 200px;

		@media (min-width: breakpoint-min(sm)) {
			height: 220px;
		}

		@media (min-width: breakpoint-min(lg)) {
			height: 240px;
		}
	}
}
