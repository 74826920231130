@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'sidebar-widget';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	margin-bottom: $margin-medium;

	@media (min-width: breakpoint-min(sm)) {
		margin-bottom: 20px;
	}

	@media (min-width: breakpoint-min(md)) {
		margin-bottom: $margin-large;
	}

	&:last-child {
		margin-bottom: 0;
	}
}
