@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'radiobutton';
$radiobutton-font-color: map-get($colors, black);
$radiobutton-font-family: $font-family--primary;
$radiobutton-font-weight: 600;
$radiobutton-margin: 1rem 0 0.5rem 0.5rem;
$radiobutton-primary-color: map-get($colors, primary);

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
    display: block;
    margin: $radiobutton-margin;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $radiobutton-primary-color;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background-color: map-get($colors-validation, success-green);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* disabled checkbox */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
    border-color: map-get($colors, grayC);
    cursor: not-allowed;
}

[type="radio"]:disabled:checked + label:after {
    color: map-get($colors, grayC);
    cursor: not-allowed;
}

[type="radio"]:disabled + label {
    color: map-get($colors, grayC);
    cursor: not-allowed;
}