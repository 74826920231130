@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

/* =============================================================================
  BASE
============================================================================= */

.txt--left {
	text-align: left !important;
}

.txt--center {
	text-align: center !important;
}

.txt--right {
	text-align: right !important;
}

.txt--justify {
	text-align: justify !important;
}
