@charset "UTF-8";

/* =============================================================================
FUNCTION: CHECKS THE LUMINCANCE OF A COLOR AND CHANGES THE TEXT COLOR DEPENDING ON THE LUMINANCE
USEAGE: $font-color: choose-contrast-color($background-color);
REFERENCE: https://medium.com/dev-channel/using-sass-to-automatically-pick-text-colors-4ba7645d2796
============================================================================= */

@function luminance($color) {
    $red: nth($linear-channel-values, red($color) + 1);
    $green: nth($linear-channel-values, green($color) + 1);
    $blue: nth($linear-channel-values, blue($color) + 1);
  
    @return .2126 * $red + .7152 * $green + .0722 * $blue;
  }
  
  /**
   * Calculate the contrast ratio between two colors.
   * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
   */
  @function contrast($back, $front) {
    $backLum: luminance($back) + .05;
    $foreLum: luminance($front) + .05;
  
    @return max($backLum, $foreLum) / min($backLum, $foreLum);
  }
  
  /**
   * Determine whether to use dark or light text on top of given color.
   * Returns black for dark text and white for light text.
   */
  @function choose-contrast-color($color) {
    $lightContrast: contrast($color, white);
    $darkContrast: contrast($color, black);
  
    @if ($lightContrast > $darkContrast) {
      @return white;
    }
    @else {
      @return black;
    }
  }