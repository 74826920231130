@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'attention';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	overflow: hidden;

	@media (min-width: breakpoint-min(md)) {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {

		@media (min-width: breakpoint-min(md)) {
			width: 50%;
			float: left;
			//display: flex;
		}

		p.h4, p.h3 {
			font-family: $font-family--primary;
		}

		a.h4 {
			font-family: $font-family--primary;
		}

		&__btn {
			margin-top: 30px;
			text-align: right;
		}

	}


}
