@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'newsletter';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
    background-color: map-get($colors, gray-lighter);
    max-width: 500px;
    @include box-shadow();
	margin: 0 auto;
	position: relative;
	padding-bottom: 50px;

	.o-btn {
		width: 100%;

		@media (min-width: breakpoint-min(sm)) {
			width: 45%;
		}

		@media (min-width: breakpoint-min(md)) {
			width: 35%;
		}
	}

	.o-inputfield {
		width: 100%;
		margin: 0 !important;

		@media (max-width: breakpoint-max(xs)) {
			max-width: none !important;
		}

		@media (min-width: breakpoint-min(sm)) {
			width: 55% !important;
		}

		@media (min-width: breakpoint-min(md)) {
			width: 65% !important;
		}
	}

	.opt-in-checkbox {
		margin-bottom: 30px;
		margin-top: 15px;
		color: map-get($colors, gray-blue-dark);
		-webkit-font-smoothing: antialiased;
		float: none !important;

		@media (min-width: breakpoint-min(sm)) {
			position: absolute;
			left: 15px;
			bottom: 15px;
			margin: 0;
			float: left !important;
		}

		@media (min-width: breakpoint-min(md)) {
			left: 20px;
		}

		@media (min-width: breakpoint-min(xl)) {
			left: 30px;
		}

		label {
			margin-top: 15px;
    		display: inline-block;
		}

		[type=checkbox]:checked, [type=checkbox]:not(:checked) {
			position: relative;
			display: inline-block;
			left: 0;
			top: -2px;
			margin-right: 10px;
			border: 2px solid map-get($colors, gray-blue);
		}
	}
}
