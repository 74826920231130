@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'jobs-archive';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
	&__item {
		position: relative;
	}

	&__title {
		margin-bottom: $margin-medium;
		margin-top: 0;
	}

	.o-block__title {
		position: absolute;
	}

	&__footer {
		min-height: 42px;
	}

	&__readmore {
		margin-right: $margin-medium;
	}

	&__noresults {
		text-align: center;
	}
}

.post-type-archive-vacatures {
	.m-#{$module-name}__item {
		// &:first-child {
		// 	margin-top: $margin-small;

		// 	@media (min-width: breakpoint-min(md)) {
		// 		margin: 0;
		// 	}
		// }
	}
}
