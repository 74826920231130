@charset "UTF-8";


/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'avatar';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    position: relative;
    display: block;
    margin: 0 auto;
}
