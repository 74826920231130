/* =============================================================================
	GSPACING
  	Control the default styling of most Bootstrap elements by modifying these
	variables. Mostly focused on spacing.
	You can add more entries to the $spacers map, should you need more variation.
============================================================================= */

$spacer: 1.5rem !default;
$spacers: (
	0: 0,
	1: ($spacer * 0.25),
	2: ($spacer * 0.5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3)
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%
) !default;
