@charset "UTF-8";

// https://github.com/jareware/css-architecture/blob/master/README.md

/* =============================================================================
CONFIG
============================================================================= */

/* =============================================================================
BASE
============================================================================= */

:root {
	font-size: 62.5%;
}

/* =============================================================================
BOX SIZING
============================================================================= */

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* =============================================================================
HTML & BODY
============================================================================= */

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	line-height: 1.5;
	font-weight: 400;
	@include rem(16);

	@media (min-width: breakpoint-min(md)) {
		-webkit-text-size-adjust: 100%;
	}
}

.safari6, .safari7, .safari8, .safari9, .ios6, .ios7, .ios8, .ios9 {
	body {
		display: block!important;
	}
}

// Render fixes
// @see: http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
	only screen and (min-device-pixel-ratio: 1.25),
	only screen and (min-resolution: 200dpi),
	only screen and (min-resolution: 1.25dppx) {
	html,
	body,
	button {
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

/* =============================================================================
MISC
============================================================================= */

// This helps prevent its display from getting accidentally overridden.
// While [hidden] isn’t natively supported by IE10

[hidden] {
	display: none !important;
}

// Set cursor for element with disabled attribute

[disabled] {
	cursor: not-allowed;
}
