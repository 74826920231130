@if index($hamburger-types, arrowalt) {
	/*
   * Arrow Alt
   */
	.o-hamburger--arrowalt {
		.o-hamburger-inner {
			&::before {
				transition: top 0.1s 0.1s ease,
					transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
				-webkit-transition: top 0.1s 0.1s ease,
					transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
			}

			&::after {
				transition: bottom 0.1s 0.1s ease,
					transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
				-webkit-transition: bottom 0.1s 0.1s ease,
					transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
			}
		}

		&.is--active {
			.o-hamburger-inner {
				&::before {
					top: 0;
					transform: translate3d(
							$hamburger-layer-width * -0.2,
							$hamburger-layer-width * -0.25,
							0
						)
						rotate(-45deg) scale(0.7, 1);
					-webkit-transform: translate3d(
							$hamburger-layer-width * -0.2,
							$hamburger-layer-width * -0.25,
							0
						)
						rotate(-45deg) scale(0.7, 1);
					transition: top 0.1s ease,
						transform 0.1s 0.1s
							cubic-bezier(0.895, 0.03, 0.685, 0.22);
					-webkit-transition: top 0.1s ease,
						transform 0.1s 0.1s
							cubic-bezier(0.895, 0.03, 0.685, 0.22);
				}

				&::after {
					bottom: 0;
					transform: translate3d(
							$hamburger-layer-width * -0.2,
							$hamburger-layer-width * 0.25,
							0
						)
						rotate(45deg) scale(0.7, 1);
					-webkit-transform: translate3d(
							$hamburger-layer-width * -0.2,
							$hamburger-layer-width * 0.25,
							0
						)
						rotate(45deg) scale(0.7, 1);
					transition: bottom 0.1s ease,
						transform 0.1s 0.1s
							cubic-bezier(0.895, 0.03, 0.685, 0.22);
					-webkit-transition: bottom 0.1s ease,
						transform 0.1s 0.1s
							cubic-bezier(0.895, 0.03, 0.685, 0.22);
				}
			}
		}
	}
}
