@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'divider';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
    width: 100%;
    height: 5px;
    background-color: map-get($colors, gray-dark);
    display: block;
}
