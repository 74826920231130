@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'footer';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	box-shadow: 0 0px 6px 2px rgba(0, 0, 0, 0.15);

	&__inner {
		display: flex;
	}

	&__left {
		margin-left: auto;
	}

	&__center {
		display: none;

		@media screen and (min-width: 1245px) {
			display: block;
		}
	}

	&__right {
		position: relative;
		padding-left: $padding-medium;
		margin-right: auto;
	}

	&__logo {
		position: relative;
		left: 0;
		top: -5px;
		width: 70px;
		height: 70px;
		margin-right: 12px;
		background: map-get($colors, blue-dark);
		background: url('./assets/img/beeldmerk.svg') no-repeat;
		@include border-radius(30px);

		@media (min-width: breakpoint-min(xl)) {
			margin-right: 20px;
		}

		@media screen and (min-width: 1245px) {
			top: -18px;
		}
	}

	&__socmed {
		position: relative;
		z-index: 1;
		float: right;
		top: 20px;
		position: relative;
		color: map-get($colors, gray-blue-text);

		@media screen and (min-width: 1245px) {
			top: 0;
		}

		&__label {
			float: left;

			@media screen and (min-width: 1245px) {
				float: none;
				padding-bottom: 15px;
			}
		}

		span {
			float: left;
			margin-right: 5px;
		}

		.o-icon {
			position: relative;
			top: -1px;
			float: left;
			margin-left: 5px;
			margin-bottom: 5px;
		}
	}

	&__certificate {
		position: relative;
		top: 30px;
		display: none;
		max-width: 150px;

		img {
			width: 90px;
			height: auto;;
		}

		p {
			font-size: 10px;
			color: map-get($colors, gray-blue-text);
		}

		@media screen and (min-width: 1245px) {
			display: block;
		}
	}

	&__nav {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			list-style-type: none;
			float: left;

			a {
				position: relative;
				display: block;
				color: map-get($colors, gray-blue-text);

				@media (min-width: breakpoint-min(lg)) {
					padding: 0 12px;
				}

				@media (min-width: breakpoint-min(xl)) {
					padding: 0 15px;
				}

				span {
					position: absolute;
					right: 0;
					height: 50px;
					top: -13px;
					border-left: 1px solid map-get($colors, gray-blue-text);
				}

				&:hover {
					color: map-get($colors, blue-dark);
				}
			}

			&:first-child a {
				padding-left: 0;
			}

			ul {
				margin-top: 15px;
				padding: 0;

				li {
					position: relative;
					float: none;
					line-height: 24px;
					max-width: 200px;

					span {
						position: absolute;
						top: 0;
						left: 12px;
						color: map-get($colors, gray-blue-text);

						@media (min-width: breakpoint-min(xl)) {
							left: 20px;
						}
					}

					a {
						@include rem(14);

						@media (min-width: breakpoint-min(lg)) {
							padding-left: 18px;
						}

						@media (min-width: breakpoint-min(xl)) {
							padding-left: 30px;
						}
					}
				}
			}
		}
	}

	&__bottom {
		span,
		p,
		a {
			color: map-get($colors, gray-blue-text);
			display: inline-block;
		}
	}
}
