@charset "UTF-8";

/* =============================================================================
GRID SYSTEM
Utilities for common `display` values
============================================================================= */

/* =============================================================================
FUNCTION: DISPLAY CLASSES FOR BREAKPOINTS
DESCRIPTION: Display classes for each breakpoint
============================================================================= */

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.d#{$infix}-none {
			display: none !important;
		}
		.d#{$infix}-inline {
			display: inline !important;
		}
		.d#{$infix}-inline-block {
			display: inline-block !important;
		}
		.d#{$infix}-block {
			display: block !important;
		}
		.d#{$infix}-table {
			display: table !important;
		}
		.d#{$infix}-table-row {
			display: table-row !important;
		}
		.d#{$infix}-table-cell {
			display: table-cell !important;
		}
		.d#{$infix}-flex {
			display: flex !important;
		}
		.d#{$infix}-inline-flex {
			display: inline-flex !important;
		}
	}
}

/* =============================================================================
FUNCTION: HIDDEN CLASSES MIN WIDTH BREAKPOINTS
DESCRIPTION: Hidden classes for each breakpoint
============================================================================= */

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.hidden#{$infix}-up {
			display: none !important;
		}
	}
}

/* =============================================================================
FUNCTION: HIDDEN CLASSES MAX WIDTH BREAKPOINTS
DESCRIPTION: Hidden classes for each breakpoint
============================================================================= */

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-down($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.hidden#{$infix}-down {
			display: none !important;
		}
	}
}
