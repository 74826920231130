@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'breadcrumb';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	font-family: $font-family--primary;
	color: map-get($colors, gray-blue-text);
	background-color: map-get($colors, gray-lighter);

	@media (min-width: breakpoint-min(xs)) {
		height: auto;
	}

	@media (min-width: breakpoint-min(md)) {
		margin: 0 20px;
	}

	&__crumbs {
		@media (min-width: breakpoint-min(xs)) {
			line-height: 40px;
			padding: 20px 0;
		}

		ul {
			@media (max-width: breakpoint-max(d)) {
				line-height: normal;
				padding: $padding-medium 0;
			}
		}

		li {
			display: inline;
		}

		a {
			cursor: pointer;
			color: map-get($colors, gray-blue-text);
		}

		span {
			margin: 0 5px;

			span {
				font-family: $font-family--primary--bold;
			}

			a span {
				font-family: $font-family--primary;
			}
		}
	}

	&--single {
		height: auto;
		overflow: hidden;

		@media (min-width: breakpoint-min(md)) {
			height: auto;
		}

		.m-breadcrumb__crumbs {
			@media (min-width: breakpoint-min(md)) {
				padding-right: 180px;
			}
		}

		.o-share {
			display: none;

			@media (min-width: breakpoint-min(lg)) {
				display: inline-block;
				text-align: right;
				padding: $padding-medium;
				margin-top: 0;
				margin-bottom: 0;
				top: 15px;
				right: 45px;
				position: absolute;
				min-width: 200px;
			}

			@media (min-width: breakpoint-min(xl)) {
				right: 50px;
			}
		}
	}
}
