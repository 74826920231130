@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'jobs-filter';

/* =============================================================================
BASE
============================================================================= */

.m-#{$object-name} {
	&__label {
		margin-bottom: $margin-medium;
		font-family: $merriweather--bold;
	}

	&__select {
		margin-bottom: $margin-medium;
	}
}
