@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$module-name: 'testimonial';

/* =============================================================================
BASE
============================================================================= */

.m-#{$module-name} {
    max-width: 650px;
    display: block;
    margin: 0 auto;

    &__content {
        max-width: 400px;
        display: block;
        margin: 0 auto;
        
        @media (min-width: breakpoint-min(md)) {
            max-width: 650px;
            margin: 0;
            padding-left: 230px;
        }

    }

}
