@if index($hamburger-types, vortex-r) {
	/*
   * Vortex Reverse
   */
	.o-hamburger--vortex-r {
		.o-hamburger-inner {
			transition-duration: 0.2s;
			-webkit-transition-duration: 0.2s;
			transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
			-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

			&::before,
			&::after {
				transition-duration: 0s;
				transition-delay: 0.1s;
				transition-timing-function: linear;
				-webkit-transition-duration: 0s;
				-webkit-transition-delay: 0.1s;
				-webkit-transition-timing-function: linear;
			}

			&::before {
				transition-property: top, opacity;
				-webkit-transition-property: top, opacity;
			}

			&::after {
				transition-property: bottom, transform;
				-webkit-transition-property: bottom, -webkit-transform;
			}
		}

		&.is--active {
			.o-hamburger-inner {
				transform: rotate(-765deg);
				-webkit-transform: rotate(-765deg);
				transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
				-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

				&::before,
				&::after {
					transition-delay: 0s;
					-webkit-transition-delay: 0s;
				}

				&::before {
					top: 0;
					opacity: 0;
				}

				&::after {
					bottom: 0;
					transform: rotate(-90deg);
					-webkit-transform: rotate(-90deg);
				}
			}
		}
	}
}
