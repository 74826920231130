@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

$module-name: 'content';

$content-max-width: 800px;

/* =============================================================================
  BASE
============================================================================= */

.m-#{$module-name} {
	position: relative;
	max-width: $content-max-width;

	@media (min-width: breakpoint-min(md)) {
		padding-right: $padding-medium;
	}

	@media (min-width: breakpoint-min(lg)) {
		padding-right: $padding-large;
	}

	:first-child {
		margin-top: 0;
	}

	h1 {
		margin-top: $margin-small !important;

		&:first-child {
			margin-top: 0 !important;
		}
	}

	h2 {
		@include rem(22);
		line-height: 1.2;

		@media (min-width: breakpoint-min(lg)) {
			@include rem(24);
		}
	}

	h3 {
		@include rem(18);

		@media (min-width: breakpoint-min(lg)) {
			@include rem(20);
		}
	}

	img {
		max-width: 100%;
	}

	ul li {
		padding-bottom: 5px;
	}

	&__2col-sm-up {

		@media (min-width: breakpoint-min(md)) {
			max-width: none;
			column-count: 2;
			column-gap: 30px;
		}

		@media (min-width: breakpoint-min(lg)) {
			column-gap: 45px;
		}

		@media (min-width: breakpoint-min(xl)) {
			column-gap: 60px;
		}
	}
}
