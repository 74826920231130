@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'inputfield';
$inputfield-font-family: $font-family--primary;
$inputfield-font-weight: 600;
$inputfield-radius: 0px;
$inputfield-padding: 0.9rem 1rem;
$inputfield-border-width: 2px;
$inputfield-border-style: solid;
$inputfield-border-color--primary: map-get($colors, gray-blue);
$inputfield-border-color--secondary: map-get($colors, gray-blue-dark);
$inputfield-outline: none;
$inputfield-margin: 0rem 0 1rem;
$inputfield-min-width: 100px;
$inputfield-width: 300px;
$inputfield-background-color: map-get($colors, white);
$inputfield-font-color: choose-contrast-color($inputfield-background-color);

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
    border-radius: $inputfield-radius;
    font-family: $inputfield-font-family;
    color: $inputfield-font-color;
    padding: $inputfield-padding $padding-medium;
    outline: $inputfield-outline;
	margin: $inputfield-margin;
	width: 100%;
    min-width: $inputfield-min-width;
    max-width: $inputfield-width;
    background-color: $inputfield-background-color;
	height: 42px;

	&:hover, &:focus {
		border-color: darken($inputfield-border-color--primary, 30%);
	}

    &--primary {
        border: $inputfield-border-width $inputfield-border-style $inputfield-border-color--primary;

        &:hover, &:focus {
            border-color: darken($inputfield-border-color--primary, 20%);
        }

    }

    &--secondary {
        border: $inputfield-border-width $inputfield-border-style $inputfield-border-color--secondary;

        &:hover, &:focus {
            // border-color: darken($inputfield-border-color--secondary, 10%);
        }
    }

    &--small {
        width: 100%;
        max-width: 200px;
    }

    &--medium {
        width: 100%;
        max-width: 400px;
    }

    &--large {
        width: 100%;
        max-width: 600px;
    }

    &--rounded {
        border-radius: 50px;
    }

}

input {
    border-radius: $inputfield-radius;
    border: $inputfield-border-width $inputfield-border-style $inputfield-border-color--primary;
    padding: $inputfield-padding !important;

	&:hover, &:focus {
		border-color: $inputfield-border-color--secondary;
	}
}
