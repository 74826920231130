@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

/* =============================================================================
  BASE
============================================================================= */

$z-index: (
  navbar: 1000,
  fixed: 1010,
  modal: 1020,
  popover: 1030,
  tooltip: 1040,
);
