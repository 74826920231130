@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

/* =============================================================================
  BASE
============================================================================= */

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.clearfix {
	&:after {
		display: block;
		content: '';
		clear: both;
	}
}
