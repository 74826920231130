@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'share';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	min-width: 165px;
	position: relative;
	color: map-get($colors, gray-blue-text);
	height: 40px;

	&__icon {
		position: absolute;
		right: 0;
		top: -5px;

		i {
			left: -1px;
		}
	}

	&__link {
		position: relative;
		padding-right: 45px;
		color: map-get($colors, gray-blue-text);

		&:hover {
			.o-#{$object-name}__icon {
				background: map-get($colors, gray-blue-text);

				i {
					color: map-get($colors, white);
				}
			}
		}
	}

	&__menu {
		display: none;
		position: absolute;
		top: 45px;
		right: 0;
		padding: 0.5rem;
		z-index: map-get($z-index, popover);
		background: map-get($colors, white);
		box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.15);
	}
}
