@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'quote';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	&__inner {
		display: inline-block;
		position: relative;
		max-width: 800px;
	}

	&__img {
		width: 200px;
		height: 200px;

		.o-avatar {
			background-size: 100%!important;
		}

		@media (min-width: breakpoint-min(sm)) {
			position: absolute;
		}
	}

	&__content {
		position: relative;

		@media (min-width: breakpoint-min(sm)) {
			min-height: 200px;
			padding-left: 230px;
			display: table;
		}

		&__inner {
			text-align: center;

			@media (min-width: breakpoint-min(sm)) {
				text-align: left;
				display: table-cell;
				vertical-align: middle;
			}
		}
	}

	&__name {
		font-family: $font-family--primary--bold;
		color: map-get($colors, gray-blue-text);
	}

	&__function {
		font-style: italic;
		color: map-get($colors, gray-blue-text);
	}
}
