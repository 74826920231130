@charset "UTF-8";

/* =============================================================================
MIXIN: VERTICAL ALIGN ANYTHING
USEAGE: @include vertical-align();
============================================================================= */

@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
