@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$body-bg: map-get($colors, white);
$wrapper-bg: map-get($colors, white);
$content-bg: map-get($colors, rgb(12, 12, 12));

$topbar-bg: map-get($colors, blue);
$topbar-height: 40px;

$header-bg: map-get($colors, white);
$header-height: 60px;
$header-mobile-height: 45px;

$sidebar-bg: none;

$footer-bg: map-get($colors, gray3);
$footer-height: 60px;
$footer-mobile-height: 45px;

/* =============================================================================
BASE
============================================================================= */

body {
	min-height: 100vh;
	width: 100%;
	background: $body-bg;

	&.overflow-hidden-mobile {
		overflow: hidden;
	}
}

.flexbox {
	body {
		display: flex;
		flex-direction: column;
	}
}

.no-flexbox {
	body {
		display: block;
	}
}

#l-wrapper {
	display: block;
	padding-top: 60px;

	@media (min-width: breakpoint-min(sm)) {
		padding-top: 75px;
	}

	@media (min-width: breakpoint-min(lg)) {
		padding-top: 150px;
	}

	&.is--fixed--jobs-apply {
		padding-top: 110px;

		@media (min-width: breakpoint-min(sm)) {
			padding-top: 130px;
		}

		@media (min-width: breakpoint-min(md)) {
			padding-top: 190px;
		}

		@media (min-width: breakpoint-min(lg)) {
			padding-top: 230px;
		}

		@media (min-width: breakpoint-min(xl)) {
			padding-top: 230px;
		}
	}
}

.no-flexbox {
	#l-wrapper {
		min-height: 100vh;
		@include clearfix();
	}
}

.flexbox {
	#l-wrapper {
		flex: 1 0 auto;
	}
}

#l-topbar {
	height: $topbar-height;
	background: $topbar-bg;
	display: none;

	@media (min-width: breakpoint-min(lg)) {
		display: block;
	}
}

#l-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 10;

	@media (min-width: breakpoint-min(lg)) {
		height: 150px;
	}
}

#l-content {
	background: $content-bg;
	overflow: hidden;
}

#l-sidebar {
	background: $sidebar-bg;
}

.ie10 {
	body {
		display: -ms-flexbox;
		-ms-flex-direction: column;
	}

	#l-wrapper {
		-ms-flex: 1 0 auto;
	}
}
