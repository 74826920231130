@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'icon';

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	display: block;
	width: 30px;
	height: 30px;
	border: 2px solid map-get($colors, gray-blue-text);
	text-align: center;
	line-height: 30px;
	@include border-radius(50%);
	@include transition(all, 200ms, ease-in-out);

	i {
		position: relative;
		top: -2px;
		color: map-get($colors, gray-blue-text);
		@include transition(all, 200ms, ease-in-out);
	}

	&--white {
		border: 2px solid map-get($colors, white);

		i {
			color: map-get($colors, white);
		}
	}
}

a.o-#{$object-name} {
	cursor: pointer;

	&:hover {
		background: map-get($colors, gray-blue-text);

		i {
			color: map-get($colors, white);
		}
	}

	&--white {

		&:hover {
			background: map-get($colors, white);

			i {
				color: map-get($colors, gray-blue-text);
			}
		}
	}
}
