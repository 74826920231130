@charset "UTF-8";

/* =============================================================================
  CONFIG
============================================================================= */

$object-name: 'list';
$border-width: 0px;
$border-style: solid;
$border-color: map-get($colors, primary);
$padding: 0.5rem 1rem;
$font-color: map-get($colors, black);

/* =============================================================================
  BASE
============================================================================= */

.o-#{$object-name} {
	&--reset {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	&__item {
		padding: 0;
		border-bottom: $border-width $border-style $border-color;

		&:last-child {
			border: none;
		}

		a {
			color: $font-color;
			display: block;
			padding: $padding;
			position: relative;
			word-wrap: break-word;
			@include transition(all, 200ms, ease-in-out);

			&:hover {
				background: rgba(0,0,0,0.05);
			}
		}
	}
}
