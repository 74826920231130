@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: 'select';

$select-primary-color: map-get($colors, gray-objects);
$select-secondary-color: map-get($colors, secondary);
$select-background-color: map-get($colors, gray-objects);
$select-font-color: choose-contrast-color($select-background-color);
$select-border-style: solid;
$select-border-width: 1px;
$select-transition-speed: 200ms;
$select-transition-curve: ease-in-out;
$select-min-width: 200px;
$select-padding: 10px 30px;
$select-radius: 0;
$select-margin: 5px 0;

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	border: 1px solid $select-primary-color;
	border-radius: $select-radius;

	&::before,
	&::after {
		content: '';
		position: absolute;
		pointer-events: none;
	}

	&::after {
		content: '\25BC';
		height: 23px;
		font-size: 1em;
		line-height: 1;
		right: 0.5em;
		top: 50%;
		margin-top: -0.5em;
		color: $select-primary-color;
		background-color: map-get($colors, white);
	}

	&::before {
		width: 2em;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 0 3px 3px 0;
	}

	select {
		cursor: pointer;
		width: 100%;
		border: none;
		height: 40px;
		background-color: map-get($colors, white);
		padding: 0.5em 0.75em;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		line-height: 1.5;
		font-family: $font-family--primary;
		color: $select-primary-color;
		margin: 0;
	}
}

.ie {
	.o-#{$object-name}::after {
		content: '';
	}
}

select {
	border-radius: 0;
    border: 2px solid map-get($colors, gray-blue);
    padding: .9rem 1rem;
    max-width: 300px;
    min-width: 100px;
    outline: none;
	margin: 0 0 1rem;
	background: none;
	height: 40px !important;

	&:hover, &:focus {
		border-color: map-get($colors, gray-blue-dark);
	}
}
