@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$font-family--primary: $merriweather !global;
$font-family--primary--bold: $merriweather--bold !global;
$font-family--primary--italic: $merriweather--italic !global;
$font-family--secondary: $rozha-one !global;

$marked-bg-color: #fcf899;
$content-spacing: 3rem;
$content-spacing--small: 1.5rem;

/* =============================================================================
BASE
============================================================================= */
body, pre {
	font-family: $font-family--primary, "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased!important;
}

%p {
	margin-top: 0;
	margin-bottom: $content-spacing--small;
}

p, pre {
	@extend %p;
}

/* =============================================================================
HEADINGS
============================================================================= */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.3;
	font-weight: 400;
	word-wrap: break-word;
	font-family: $font-family--secondary;
	font-weight: normal;
	margin: $content-spacing 0 $content-spacing--small 0;
	color: map-get($colors, blue-dark);
}

%h1 {
	@include rem(30);
	margin: $content-spacing 0 $content-spacing 0;
	line-height: 1.2;

	@media (min-width: breakpoint-min(md)) {
		@include rem(32);
	}

	@media (min-width: breakpoint-min(lg)) {
		@include rem(36);
	}
}

.h1,
h1 {
	@extend %h1;
}

%h2 {
	@include rem(26);
	line-height: 1.2;

	@media (min-width: breakpoint-min(md)) {
		@include rem(26);
	}

	@media (min-width: breakpoint-min(lg)) {
		@include rem(28);
	}
}

.h2,
h2 {
	@extend %h2;
}

%h3 {
	@include rem(22);
	line-height: 1.2;

	@media (min-width: breakpoint-min(lg)) {
		@include rem(24);
	}
}

.h3,
h3 {
	@extend %h3;
}

.h4,
h4 {
	@include rem(18);

	@media (min-width: breakpoint-min(lg)) {
		@include rem(20);
	}
}

.h5,
h5 {
	@include rem(16);

	@media (min-width: breakpoint-min(lg)) {
		@include rem(18);
	}
}

.h6,
h6 {
	@include rem(16);

	@media (min-width: breakpoint-min(lg)) {
		@include rem(18);
	}
}

/* =============================================================================
LINKS
============================================================================= */

%a {
	text-decoration: none;
	@include transition(color, 200ms, ease);
	color: map-get($colors, blue);

	&:focus {
	}

	&:hover {
	}

	&:visited {
	}
}

a {
	@extend %a;
}

/* =============================================================================
TEXT STUFFS
============================================================================= */

b,
strong {
	font-weight: $font-weight-extrabold;
	font-family: $font-family--primary--bold;
}

i,
em {
	font-style: italic;
	font-family: $font-family--primary;
}
pre {
	line-height:1.5;
	font-size:100%!important;
	white-space: pre-wrap;       /* css-3 */
 	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
 	white-space: -pre-wrap;      /* Opera 4-6 */
 	white-space: -o-pre-wrap;    /* Opera 7 */
 	word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

/* =============================================================================
HELPER CLASSES
============================================================================= */

.small,
small {
	font-family: $font-family--primary;
	line-height: 1;
	font-weight: 400;
}

.lead {
	font-weight: 400;
	line-height: 1.3;
	@include rem(20);

	@media (min-width: breakpoint-min(md)) {
		@include rem(22);
	}

	@media (min-width: breakpoint-min(lg)) {
		@include rem(24);
	}
}

.mark,
mark {
	padding: 0.2rem;
	background-color: $marked-bg-color;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: $content-spacing--small;
	font-size: 90%;
}

.txt--large {
	@include rem(18);

	@media (min-width: breakpoint-min(md)) {
		@include rem(20);
	}
}

/* =============================================================================
ELEMENTS
============================================================================= */

%list {
	margin-bottom: $content-spacing--small;

	ul,
	ol {
		margin-bottom: 0;
	}
}

ul,
ol {
	@extend %list;
}
