@charset "UTF-8";

/* =============================================================================
CONFIG
============================================================================= */

$object-name: "person";

/* =============================================================================
BASE
============================================================================= */

.o-#{$object-name} {
	position: relative;
	width: 100%;
	height: 100%;
	background: #000;

	@media (min-width: breakpoint-min(sm)) {
		height: 200px;
	}

	@media (min-width: breakpoint-min(md)) {
		height: 100%;
	}

	&__img {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;

		@media (min-width: breakpoint-min(sm)) {
			width: 200px;
		}

		@media (min-width: breakpoint-min(md)) {
			width: auto;
		}

		img {
			width: 100%;
			height: auto;
			@include mix-blend-multiply();
		}
	}

	&__content {
		width: 100%;

		@media (min-width: breakpoint-min(sm)) {
			position: absolute;
			padding-left: 230px;
			top: 0;
			left: 0;
			height: 200px;
			z-index: 0;
		}

		@media (min-width: breakpoint-min(md)) {
			padding-left: 20px;
			height: 100%;
			display: none;
			z-index: 1;
		}

		@media (min-width: breakpoint-min(lg)) {
			padding-left: 30px;
		}
	}

	&__name {
	}

	&__description {
		padding-right: 40px;

		@media (min-width: breakpoint-min(sm)) {
			padding-right: 0;
		}
	}

	&__phone {
	}

	&__email {
		word-break: break-word;
	}

	&__social {
	}

	.o-icon {
		position: absolute;
		bottom: 15px;
		right: 15px;
	}

	&:hover {
		.o-#{$object-name}__content {
			display: block;
		}
	}
}
